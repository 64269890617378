<template>
  <div>
    <table border="0" cellspacing="0" cellpadding="0">
      <tr>
        <td width="80%">
          <v-file-input
            ref="myfile"
            v-model="files"
            show-size
            outlined
            rounded
            label="Upload Logo"
            name="uploadADocument"
          ></v-file-input>
        </td>
        <td width="20%">
          <v-btn
            color="black darken-1"
            text
            @click="uploadDoc"
          >
            Upload
          </v-btn>
        </td>
      </tr>
    </table>
  </div>
</template>

<script>
import { integer } from 'vee-validate/dist/rules'
  export default {
    name: 'LogoUpload',
    props: {
      vizionId: String,
    },
    data: () => ({
      files: null,
    }),
    methods: {
      uploadDoc () {
        this.$emit('upload-new-doc', { vizionId: this.vizionId, files: this.files })
        this.files = null
      },
    },
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .containerwrapper {
    display: grid;
    grid-gap: 15px;
    grid-template-columns: 1fr 4fr 1fr 4fr 1fr;
  }
  .containerbottomwrapper {
    display: grid;
    grid-gap: 15px;
    grid-template-columns: 3fr 1fr 3fr 1fr 3fr;
  }
  .containercol {
    text-align: left;
    font-size: small;
    line-height: 34px;
  }
</style>
