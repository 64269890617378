<template>
  <div>
    <base-section
      id="portal"
      class="lightgrey"
      space="0"
    >
      <div style="text-align:center;">
        <div class="col s12">
          <div class="containerbottomwrapper">
            <div class="containercol">
              <h5>Vizion</h5>
              <v-btn
                v-if="true"
                color="green darken-1"
                text
                @click="runTest"
              >
                Run Test
              </v-btn>
            </div>
            <div class="containercol">&nbsp;</div>
            <div class="containercol">
              Top Center
            </div>
            <div class="containercol">&nbsp;</div>
            <div class="containercol">
              <v-btn
                color="green darken-1"
                text
                @click="backToVizions"
              >
                Back to Vizions
              </v-btn>
            </div>
          </div>
          <hr>
          <div class="containerwrapper">
            <div class="containercol">&nbsp;</div>
            <div class="containercol">
              Center-Left
            </div>
            <div class="containercol">&nbsp;</div>
            <div class="containercol">
              Center-Right
            </div>
            <div class="containercol">&nbsp;</div>
          </div>
        </div>
        <div class="col s12" v-if="questions.length>0">
          <div v-if="hasLogo">
            <v-img
              height="150"
              contain
              :src="vizionData.logo.downloadURL"
            ></v-img> 
            <v-btn
              v-if="!changeLogo"
              color="green darken-1"
              text
              @click="changeLogo=!changeLogo"
            >
              Change Logo
            </v-btn>
          </div>
          <div v-if="!hasLogo||changeLogo">
            <logoUpload :vizionId="vizionData.id" @upload-new-doc="uploadDoc" />
          </div>
          <v-stepper
            v-model="vizionstep"
            vertical
          >
            <v-stepper-step
              :complete="vizionstep > 1"
              step="1"
            >
              <h3>{{ questions[0].question }}</h3>
            </v-stepper-step>
            <v-stepper-content step="1">
              <vizion-question v-model="vizionData.level1" levelNumber="1" @goto-step="gotoStep" />
            </v-stepper-content>
            <v-stepper-step
              :complete="vizionstep > 2"
              step="2"
            >
              <h3>{{ questions[1].question }}</h3>
            </v-stepper-step>
            <v-stepper-content step="2">
              <vizion-question v-model="vizionData.level2" levelNumber="2" @goto-step="gotoStep" />
            </v-stepper-content>
            <v-stepper-step
              :complete="vizionstep > 3"
              step="3"
            >
              <h3>{{ questions[2].question }}</h3>
            </v-stepper-step>
            <v-stepper-content step="3">
              <vizion-question v-model="vizionData.level3" levelNumber="3" @goto-step="gotoStep" />
            </v-stepper-content>
            <v-stepper-step
              :complete="vizionstep > 4"
              step="4"
            >
              <h3>{{ questions[3].question }}</h3>
            </v-stepper-step>
            <v-stepper-content step="4">
              <vizion-question v-model="vizionData.level4" levelNumber="4" @goto-step="gotoStep" />
            </v-stepper-content>
            <v-stepper-step
              :complete="vizionstep > 5"
              step="5"
            >
              <h3>{{ questions[4].question }}</h3>
            </v-stepper-step>
            <v-stepper-content step="5">
              <vizion-question v-model="vizionData.level5" levelNumber="5" @goto-step="gotoStep" />
            </v-stepper-content>
            <v-stepper-step
              :complete="vizionstep > 6"
              step="6"
            >
              <h3>{{ questions[5].question }}</h3>
            </v-stepper-step>
            
            <v-stepper-content step="6">
              <vizion-question v-model="vizionData.level6" levelNumber="6" @goto-step="gotoStep" />
            </v-stepper-content>
            <v-stepper-step
              :complete="vizionstep > 7"
              step="7"
            >
              <h3>{{ questions[6].question }}</h3>
            </v-stepper-step>

            <v-stepper-content step="7">
              <vizion-question v-model="vizionData.level7" levelNumber="7" @goto-step="gotoStep" />
            </v-stepper-content>
          </v-stepper>
        </div>
        <router-view />
      </div>
    </base-section>
  </div>
</template>

<script>
  // import * as api from '@/functions/api.js'
  import { mapGetters } from 'vuex'
  import { System } from '@/database/system'
  import { Vizions } from '@/database/deal'
  import { FirebaseResults } from '@/database'
  import { Storage } from '@/database/config/firebase'
  import VizionQuestion from '@/components/VizionQuestion'
  import logoUpload from '@/components/LogoUpload'

  const storageRef = Storage.ref()
  const getDownloadURL = async (path, filename) => { 
    const objectRef = await storageRef.child(path + filename)
    var returnURL = await objectRef.getDownloadURL().then((url) => {
      return url
    })
    return returnURL
  }

  export default {
    name: 'SectionVizion',
    components: {
      VizionQuestion,
      logoUpload,
    },
    data: () => ({
      vizionId: '',
      changeLogo: false,
      vizionData: {
        CompanyName: '',
        logo: {
          DocFileName: 'NoLogo.png',
          DocPath: 'NOLOGO',
          downloadURL: '/images/NoLogo.png',
        },
        title: '',
      },
      questions: [],
      vizionstep: 1,
    }),
    provide: {
      heading: { align: 'center' },
    },
    computed: {
      ...mapGetters({
        user: 'user',
      }),
      currentUserType () {
        return this.user.data.usertype.toLowerCase()
      },
      currentUserId () {
        return this.user.data.id
      },
      hasLogo () {
        return this.vizionData.logo.DocPath !== 'NOLOGO'
      },
    },
    watch: {
    },
    mounted () {
      this.vizionId = this.$route.params.vizionid
      this.initialize()
    },
    methods: {
      initialize () {
        Vizions.findOne(this.vizionId).then(doc => {
          this.vizionData = doc.data()
          this.vizionData.id = this.vizionId
          if (!this.vizionData.logo) { 
            // Create Empty VizionLogo
            this.vizionData.logo = {
              DocFileName: 'NoLogo.png',
              DocPath: 'NOLOGO',
              downloadURL: '/images/NoLogo.png',
            }
          }
          console.log(this.vizionData)
          System.findOne('questions').then(doc => {
            const results = doc.data().data
            console.log(results)
            this.questions = results
            for (var i = 0; i < this.questions.length; ++i){
              this.questions[i].question = this.questions[i].question.replace('{CompanyName}', this.vizionData.CompanyName)
            }
          })
        })
      },
      findObjectByKey(array, key, value) {
        for (var i = 0; i < array.length; i++) {
            if (array[i][key] === value) {
                return array[i]
            }
        }
        return null
      },
      saveVizionData (doRedirect) {
        this.vizionData.lastModified = new Date()
        Vizions.update(this.vizionId, this.vizionData).then(results => {
          if (doRedirect) {
            setTimeout(this.redirectVizionPretty(this.vizionId), 1000)
          }
          // console.log('Status Set.');
        })
      },
      gotoPrevStep (step) {
        this.gotoStep(step)
      },
      gotoNextStep (step) {
        this.gotoStep(step)
      },
      gotoStep (step) {
        // save the data, then move
        console.log(step)
        this.saveVizionData(step === 8)
        this.vizionstep = step
      },
      redirectVizionPretty (id) {
        this.$router.push('/vizion/display/' + id).catch(err => {
          console.log('error redirecting : ', err)
        })
      },
      backToVizions () {
        this.$router.push('/vizion/dashboard').catch(err => {
          console.log('error redirecting : ', err)
        })
      },
      runTest () {
        console.log(this.vizionData)
      },
      async uploadDoc (uploadObject) {
        // console.log(uploadObject)
        if (uploadObject.files) {
          const thisFile = uploadObject.files
          const thisVizionId = uploadObject.vizionId
          const thisVizionData = this.vizionData
          var uploadTask = storageRef.child(thisVizionId + '/logoUpload/' + thisFile.name).put(thisFile)
          uploadTask.on('state_changed', snapshot => {
            // console.log('uploaded/state_changed in storage: ', snapshot)
          }, error => {
              // Error
              this.uploadError = error
              this.uploadTask = null
          }, () => {
            // Upload finished
            this.vizionData.logo = uploadTask.snapshot.ref.getDownloadURL().then(function(downloadURL) {
              // Now update the Data Object
              var newDoc = {
                DocPath: thisVizionId + '/logoUpload/',
                DocFileName: thisFile.name,
                downloadURL: downloadURL,
                UploadTimestamp: Date(),
              }
              thisVizionData.logo = newDoc
              return Vizions.update(thisVizionId, thisVizionData).then(results => {
                return newDoc
              })
            }).then((results) => { 
              this.changeLogo = false
              // console.log('results', results);
              // console.log(this.vizionData.logo)
            })
            
            console.log('downloadURL(snapshot) from firebase: ', uploadTask.snapshot)
          })
        }
      },
    },
  }
</script>
<style scoped>
table {
  width: 100%;
  display:table;
  border-collapse: collapse;
  border-spacing: 0;
  text-indent: initial;
}
table, th, td {
  border: none;
  line-height: 2.5;
  font-size: 15px;
}
tr {
  border-bottom: 1px solid rgba(0,0,0,0.12);
}
*, *:before, *:after {
  -webkit-box-sizing: inherit;
  box-sizing: inherit;
}
  .containerwrapper {
    display: grid;
    grid-gap: 15px;
    grid-template-columns: 1fr 4fr 1fr 4fr 1fr;
  }
  .containerbottomwrapper {
    display: grid;
    grid-gap: 15px;
    grid-template-columns: 3fr 1fr 3fr 1fr 3fr;
  }
  .containercol {
    text-align: left;
    font-size: small;
    line-height: 34px;
  }
  .form-buttons {
    display: flex;
    justify-content: flex-end;
  }
</style>
