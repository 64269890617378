<template>
  <div>
    <v-container>
      <v-row>
        <v-col 
          cols="12" 
          style="text-align:left"
        >
          <h4>Who is Responsible for this?</h4>
        </v-col>
        <v-col cols="6">
          <v-text-field
            v-model="level.name"
            v-on:input="updateValue"
            :label="level.title.length>0?level.title+' Name':'Name'"
          ></v-text-field>
        </v-col>
        <v-col cols="6">
          <v-text-field
            v-model="level.title"
            v-on:input="updateValue"
            label="Title"
          ></v-text-field>
        </v-col>
      </v-row>
    </v-container>
    <table border="0" cellspacing="0" cellpadding="0" width="100%">
      <tr>
        <td width="20%">
          &nbsp;
        </td>
        <td width="80%">
          <v-text-field
            v-model="level.answers[0]"
            v-on:input="updateValue"
            name="input-1-1"
            label="First Answer"
            outlined
          ></v-text-field>
        </td>
      </tr>
      <tr>
        <td width="20%">
          &nbsp;
        </td>
        <td width="80%">
          <v-text-field
            v-model="level.answers[1]"
            v-on:input="updateValue"
            name="input-1-2"
            label="Second Answer"
            outlined
          ></v-text-field>
        </td>
      </tr>
      <tr>
        <td width="20%">
          &nbsp;
        </td>
        <td width="80%">
          <v-text-field
            v-model="level.answers[2]"
            v-on:input="updateValue"
            name="input-1-3"
            label="Third Answer"
            outlined
          ></v-text-field>
        </td>
      </tr>
    </table>
    <v-btn
      v-if="levelNumber > 1"
      color="information"
      @click="gotoPrevStep()"
    >
      Back
    </v-btn>
    <v-btn
      v-if="levelNumber < 8"
      color="primary"
      @click="gotoNextStep()"
    >
      Next
    </v-btn>
  </div>
</template>

<script>
  export default {
    name: 'VizionQuestion',
    props: ['value', 'levelNumber'], 
    data: () => ({
      level: {
        name: '',
        title: '',
        answers: [],
      },
    }),
    mounted () {
      this.initialize()
    },
    methods: {
      initialize () {
        this.level = this.value
      },
      gotoPrevStep () {
        this.gotoStep(parseInt(this.levelNumber) - 1)
      },
      gotoNextStep () {
        console.log('currentLevel', this.levelNumber)
        this.gotoStep(parseInt(this.levelNumber) + 1)
      },
      gotoStep(step) {
        console.log('nextLevel', step)
        this.$emit('goto-step', step)
      },
      updateValue: function () {
        this.$emit('input', this.level)
      },
    },
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.roundedAnswer {
  border-radius: 25px;
  padding: 10px;
  margin: 10px;
}
</style>
