import { render, staticRenderFns } from "./Vizion.vue?vue&type=template&id=23af51ac&scoped=true&"
import script from "./Vizion.vue?vue&type=script&lang=js&"
export * from "./Vizion.vue?vue&type=script&lang=js&"
import style0 from "./Vizion.vue?vue&type=style&index=0&id=23af51ac&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "23af51ac",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VImg } from 'vuetify/lib/components/VImg';
import { VStepper } from 'vuetify/lib/components/VStepper';
import { VStepperContent } from 'vuetify/lib/components/VStepper';
import { VStepperStep } from 'vuetify/lib/components/VStepper';
installComponents(component, {VBtn,VImg,VStepper,VStepperContent,VStepperStep})
